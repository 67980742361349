<template>
  <div class="table-global">
    <!-- Start card for float effect -->
    <!-- <v-card class="table-global"> -->
    <!-- Start simple table -->
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead v-if="!bLoading">
          <tr>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4"
            >
              ID
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4"
            >
              Proyecto
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4"
            >
              Fecha de creación
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4"
            >
              Proveedor
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4"
            >
              Método de pago
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4"
            >
              Subtotal recibido
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4; width: auto !important"
            >
              Estatus
            </th>
            <th
              width="14%"
              class="text-left"
              style="border-bottom: 3px solid #e4e4e4; width: auto !important"
            >
              Estado de pago
            </th>
            <th
              width="14%"
              class="text-left"
              style="
                  text-align: center !important;
                  border-bottom: 3px solid #e4e4e4;
                  width: 280px !important;
                "
            >
              Acción
            </th>
          </tr>
        </thead>
        <tbody v-if="!bLoading">
          <tr v-for="(item, index) in items" :key="index">
            <td class="text-left">
              {{ item.sPurchaseOrderId }}
            </td>
            <td class="text-left">
              {{ item.sProjectName }}
            </td>
            <td class="text-left">
              {{ item.created_at }}
            </td>
            <td class="text-left">
              {{ item.sSupplierName }}
            </td>
            <td class="text-left">
              {{ item.sPaymentMethod }}
            </td>
            <td class="text-left">${{ mask(item.dTotalPrice) }}</td>
            <td
              class="text-left"
              :style="
                'font-weight: bold; color: ' +
                  getStatusColor(item.PurchaseOrderStatusRecords.sName)
              "
            >
              {{ item.PurchaseOrderStatusRecords.sName }}
            </td>
            <td
              class="text-left"
              :style="
                'font-weight: bold; color: ' +
                  getColorsOCPaymentStatus(item.sPurchaseOrderPaymentStatus)
              "
            >
              {{ item.sPurchaseOrderPaymentStatus }}
            </td>
            <td style="white-space: pre">
              <div style="text-align: right !important">
                <!-- <v-btn
                  v-if="item.PurchaseOrderStatusRecords.sName !== 'Pagada'"
                  icon
                  color="black"
                  @click="setPay(item)"
                >
                  <v-icon> mdi-currency-usd </v-icon>
                </v-btn> -->

                <!-- <v-btn v-else  style="color:white !important" icon disabled  >.</v-btn> -->
                <v-btn
                  icon
                  color="black"
                  @click="getDetailOC(item.sPurchaseOrderId)"
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>

                <v-btn icon color="#FF5757" @click="deleteProjects(item)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot
          v-if="!bLoading"
          style="position: sticky; bottom: 0; background: #fff"
        >
          <tr>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            ></th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            ></th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            ></th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            ></th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            ></th>
            <th
              class="text-left"
              style="
                  color: red !important;
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            >
              ${{ mask(dTotalSubTotal) }}
            </th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                "
            ></th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                  width: 145px;
                "
            ></th>
            <th
              class="text-left"
              style="
                  border-bottom: 3px solid #e4e4e4;
                  border-top: 3px solid #e4e4e4;
                  width: 145px;
                "
            ></th>
          </tr>
        </tfoot>
        <div v-show="bLoading">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
          <div class="content-text-loading">
            <p class="text-loading">Cargando... Espere por favor</p>
          </div>
        </div>
      </template>
    </v-simple-table>
    <div class="footer-table">
      <div style="margin-right: 30px">
        <p>
          Filas por página:
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="footer-table-dropdown"
                text
                v-bind="attrs"
                v-on="on"
              >
                {{ iItemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeTablePagination(10)">
                <v-list-item-title>10</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeTablePagination(25)">
                <v-list-item-title>25</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeTablePagination(50)">
                <v-list-item-title>50</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeTablePagination(100)">
                <v-list-item-title>100</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </p>
      </div>
      <div style="margin-right: 15px">
        <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
      </div>
      <div>
        <v-btn
          elevation="0"
          class="footer-table-buttons"
          @click="backPageContacts"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          elevation="0"
          class="footer-table-buttons"
          @click="nextPageContacts"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- End simple table -->
    <!-- Start pagination -->

    <!-- End pagination -->
    <!-- </v-card> -->
    <!-- End card for float effect -->
    <!-- Start dialog to show order detail -->
    <supplier-order-detail
      @close="openDialogDetails = false"
      :openDialogDetails="openDialogDetails"
      :itemDetails="itemToView"
    >
    </supplier-order-detail>
    <!-- End dialog to show order detail -->
    <!-- Start dialog to show paument -->
    <!-- <supplier-order-payment
      @close="openDialogPayment = false"
      :bActive="openDialogPayment"
      :item="itemToPay"
    >
    </supplier-order-payment> -->
    <!-- End  -->

    <dialog-partial-payments
      @close="openDialogPayment = false"
      :bActive="openDialogPayment"
      :dTotalPayments="dTotalPayments"
      :sOCId="sOCId"
    />
  </div>
</template>
<script>
export default {
  components: {
    // dialog to shwo order detail
    SupplierOrderDetail: () => import("../Supplier/Details"),
    // dialog to shoow cofirm payment
    // SupplierOrderPayment: () => import("../Supplier/Payment"),
    //import dialog partial payments component
    DialogPartialPayments: () =>
      import("@/components/core/PartialPayments/dialog-partial-payments.vue"),
  },
  name: "ProvidersComponent",
  data() {
    return {
      // variables for supplier order detail dialog
      openDialogDetails: false,
      openDialogPayment: false,
      itemToView: null,
      itemToPay: null,
      //
      supplierSelected: null,
      bLoading: false,
      // number of pages
      iCurrentPage: 1,
      iPageNumber: 1,
      // store db items (Pickers)
      mainstatus: "En proceso",
      Estatus: ["En proceso", "Detenido", "Terminado"],
      items: [],
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      openDialogDetails: false,
      itemDetails: {},
      openDialogDelete: false,
      itemDelete: {},
      dTotalSubTotal: 0,

      openDialogPayment: false,
      dTotalPayments: 0,
      sOCId: "",
    };
  },
  mounted() {
    this.getSupplier();
    this.getItems();
  },
  computed: {
    // return headers from texts
    arrHeaders() {
      return this.texts.headers;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    sStatus() {
      return this.$store.state.sStatus;
    },
    daysDebt() {
      return this.$store.state.daysDebt;
    },
    token() {
      return "Bearer " + this.$store.state.token;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    aProject() {
      return this.$store.state.aProject;
    },
  },
  watch: {
    sStatus: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    daysDebt: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    endDate: function() {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getItems();
      }
    },
    startDate: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    iCurrentPage: function() {
      this.getItems();
    },
    refreshTable: function() {
      this.getItems();
    },
    aProject: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
  },
  methods: {
    mask(price) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(price); /* $2,500.00 */
    },
    getStatusColor(status) {
      switch (status) {
        case "Aprobada":
          return "#01B570";
        case "Pagado":
          return "#000";
        case "Correcciones":
          return "rgb(199, 0, 0)";
        case "Borrador":
          return "rgb(115 115 115 / 98%)";
        case "Enviada":
          return "#000";
        default:
          return "#000";
      }
    },
    getColorsOCPaymentStatus(status) {
      switch (status) {
        case "Pagado":
          return "#97e6b6";
        case "No pagado":
          return "#ff7d7d";
        case "Pagado parcial":
          return "#ffce00";
        default:
          break;
      }
    },
    setPay(item) {
      this.itemToPay = item;
      this.sOCId = item.sPurchaseOrderId;
      this.dTotalPayments = 0;
      this.openDialogPayment = true;
    },
    getItems() {
      this.bLoading = true;
      this.items = [];
      // get items
      // /api/v1/purchase_order/supplier/:sSupplierId?iPageNumber=1
      db.get(
        `${uri}/api/v1/purchase_order/supplier/` +
          this.$route.params.sSupplierId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
            sPurchaseOrderStatusId: this.sStatus,
            sSearch: this.sSearch,
            sDebtDays: this.daysDebt,
            arrProjectIds: this.aProject,
          },
        }
      )
        .then((resp) => {
          this.$store.commit("setDebt", resp.data.total);
          this.bLoading = false;
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.items = resp.data.orders;
          this.totalItems = resp.data.iTotal;
          this.dTotalSubTotal = resp.data.dSumSubTotal;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getSupplier() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/supplier/` + this.$route.params.sSupplierId + "/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.$store.commit(
            "setSupplierFullname",
            resp.data.supplier.sFullName
          );
          this.supplierSelected = resp.data.supplier.sFullName;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    viewDetails(item) {
      this.itemToView = item;
      this.openDialogDetails = true;
    },
    getDetailOC(sOCId) {
      // this.$router.push({
      //   name: "SupplierDetailOC",
      //   params: { sOCId: sOCId },
      // });
      this.$router.push({
        name: "OCProviderDetail",
        // params: { sIdProvider: this.$route.params.sSupplierId, sOCId: sOCId },
        params: {
          sIdProvider: this.$route.params.sSupplierId,
          sOCId: sOCId,
        },
      });
    },
    setEdit(e) {
      /*this.openDialogDetails = true;
      this.itemDetails = e;*/
    },
    deleteProjects(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sPurchaseOrderId,
        route: "/api/v1/purchase_order/" + item.sPurchaseOrderId,
        unit: " órden.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
      /*this.openDialogDelete = true;
      this.itemDelete = e;*/
    },
    changeTablePagination(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getItems();
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    // get the items from api
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style scoped>
.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.table-contacts thead {
  background-color: #f7f8ff;
}

.table-contacts thead tr th {
  border-bottom: 1px solid #f7f8ff !important;
  color: #47567b !important;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.table-contacts tbody tr td {
  color: #250e62;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  border-bottom: 0.5px solid #979797 !important;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}
.actionsIcon {
  font-size: 20px !important;
}
.TableWrapper {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px !important;
  width: 100%;
  margin-bottom: 25px;
}
</style>
